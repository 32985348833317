/* eslint-disable no-prototype-builtins */
/* eslint-disable no-param-reassign */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { event as gaEvent } from 'react-ga';
import { withRouter } from 'react-router-dom';
import InputWithValidation from 'components/InputWithValidation';
import DualButtons from 'components/DualButtons';
import IconButton from 'components/IconButton/IconButton';
import Hr from 'components/CardShell/Hr';
import Header from 'components/CardShell/Header';
import SecureInput from 'components/SecureInput/SecureInput';
import cx from 'classnames';
import {
  clearCreateConfiguration as clearCreateConfigurationAction,
  addConfiguration as addConfigurationAction,
  setCreateConfiguration as setCreateConfigurationAction,
} from 'actions';
import validateConfiguration from 'helpers/validateConfiguration';
import {
  isSecure,
  renderInputWithValidation,
} from 'helpers/trackingSettings/configuration/integrationHelpers';
import CopyToClipboardIcon from 'images/CopyToClipboard.svg';
import copy from 'copy-to-clipboard';
import ReactTooltip from 'react-tooltip';
// eslint-disable-next-line import/no-extraneous-dependencies
import styles from './trackingServices.scss';
import formStyles from './formStyles.scss';

export class CTrackingService extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageIndex: 0,
      submitted: false,
    };
  }

  handleCancelClick = () => {
    gaEvent({
      category: 'CarrierConnector',
      action: 'Cancel configuration creation',
    });
    this.props.clearCreateConfiguration();
    this.props.history.push('/settings/carrier-connectors');
  };

  toggleReadOnly = integrationProperty => {
    const configuration = {
      ...this.props.createConfiguration.configuration,
      integrationProperties:
        this.props.createConfiguration.configuration.integrationProperties.map(item => (item.fieldName !== integrationProperty.fieldName ? item : {
          ...item,
          readOnly: !integrationProperty.readOnly,
          originalValue: integrationProperty.hasOwnProperty('originalValue') ? integrationProperty.originalValue : integrationProperty.fieldValue,
          fieldValue: integrationProperty.readOnly ? '' : integrationProperty.originalValue,
        })),
    };
    this.props.createConfiguration.configuration = configuration;
    this.props.setCreateConfiguration({ configuration });
  };

  prepareConfigurationForValidation = (configuration) => {
    // In case the secure values are not edited, only the required rule should be kept, the others should be ignored
    // (all the other rules would fail because the form does not contain the actual value, it contains a hidden value)
    if (configuration) {
      const configCopy = JSON.parse(JSON.stringify(configuration));
      configCopy.configuration.integrationProperties.forEach((x) => {
        if (isSecure(x) && x.readOnly !== false) {
          x.validationRules = x.validationRules.filter(rule => rule.ruleType === 'required');
        }
      });
      return configCopy;
    }
    return configuration;
  }

  handleAddClick = () => {
    gaEvent({
      category: 'CarrierConnector',
      action: 'Add new configuration',
    });

    const configChecked = this.prepareConfigurationForValidation(this.props.createConfiguration);
    const isValid = validateConfiguration(configChecked);
    this.props.setCreateConfiguration({ ...this.props.createConfiguration, errors: configChecked.errors });

    this.setState({ submitted: true });

    if (isValid) {
      const integrationValues = this.props.createConfiguration.configuration.integrationProperties.map(x => ({
        fieldName: x.fieldName,
        fieldValue: x.fieldValue,
      }));

      const configuration = {
        trackingServiceId: this.props.createConfiguration.configuration.trackingServiceId,
        name: this.props.createConfiguration.configuration.name,
        isEnabled: false,
        integration_values: integrationValues,
      };

      this.props.addConfiguration(configuration);
      this.props.history.push('/settings/carrier-connectors');
    }
  };

  handleChange = (e) => {
    const nameAndValue = ('target' in e)
      ? {
        name: e.target.name,
        value: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
      }
      : e;
    gaEvent({
      category: 'CarrierConnector',
      action: `Changed field ${nameAndValue.name}`,
    });

    let configuration;
    if (nameAndValue.name.startsWith('integrationProperties.')) {
      const name = nameAndValue.name.substr(22);
      const integrationProperties = this.props.createConfiguration.configuration.integrationProperties.map(x => ({
        fieldName: x.fieldName,
        fieldValue: x.fieldName === name ? nameAndValue.value : x.fieldValue,
        fieldTitle: x.fieldTitle,
        fieldDescription: x.fieldDescription,
        fieldType: x.fieldType,
        fieldUrl: x.fieldUrl,
        validationRules: x.validationRules,
        readOnly: x.readOnly,
        originalValue: x.originalValue === undefined ? x.fieldValue : x.originalValue,
      }));

      configuration = {
        ...(this.props.createConfiguration.configuration),
        integrationProperties,
      };
    } else {
      configuration = {
        ...(this.props.createConfiguration.configuration),
        [nameAndValue.name]: nameAndValue.value,
      };
    }

    this.props.createConfiguration.configuration = configuration;

    if (this.state.submitted) {
      const configChecked = this.prepareConfigurationForValidation(this.props.createConfiguration);
      validateConfiguration(configChecked);
      this.props.createConfiguration.errors = configChecked.errors;
    }

    this.props.setCreateConfiguration({ configuration, errors: this.props.createConfiguration.errors });
  };

  renderIntegrationProperty = (integrationProperty, errors) => {
    const config = {
      name: `integrationProperties.${integrationProperty.fieldName}`,
      value: integrationProperty.fieldValue,
      label: integrationProperty.fieldTitle,
      errors,
      onChange: this.handleChange,
    };

    if (isSecure(integrationProperty)) {
      if (integrationProperty.readOnly === undefined) {
        integrationProperty.readOnly = true;
      }

      return (
        <>
          <SecureInput
            integrationProperty={integrationProperty}
            handleChange={this.handleChange}
            toggleReadOnly={this.toggleReadOnly}
          />
        </>
      );
    }

    return renderInputWithValidation(integrationProperty, config);
  }

  render() {
    const { createConfiguration } = this.props;
    return (
      <div className={formStyles.container}>
        <ReactTooltip effect="solid" isCapture />
        <Header>{this.props.createConfiguration.configuration.carrierIntegrationName}</Header>
        <Hr />
        <div className={cx(formStyles.dualSelect, 'mt-3')}>
          <button
            type="button"
            className={
              cx(
                this.state.pageIndex === 0 ? formStyles.dualSelectButtonActive : '',
                formStyles.dualSelectButton,
                'detail2',
              )
            }
            onClick={() => this.setState({ pageIndex: 0 })}
          >
            I know my details
          </button>
          <button
            type="button"
            className={
              cx(
                this.state.pageIndex === 1 ? formStyles.dualSelectButtonActive : '',
                formStyles.dualSelectButton,
                'detail2',
              )
            }
            onClick={() => this.setState({ pageIndex: 1 })}
          >
            I don&apos;t know my details
          </button>
        </div>
        {this.state.pageIndex === 0 && (
          <div>
            <div className="mt-5">
              <InputWithValidation
                name="name"
                label="Give your connector a name (If required)"
                value={createConfiguration.configuration.name}
                onChange={this.handleChange}
                errors={createConfiguration.errors}
              />
            </div>
            {createConfiguration.configuration.integrationProperties.map(x => (
              <div key={x.fieldName}>
                <div className="mt-4">
                  {this.renderIntegrationProperty(x, createConfiguration.errors)}
                </div>
              </div>
            ))}
            <div className="mt-4">
              <InputWithValidation
                name="CarrierReference"
                label="Carrier Reference"
                value={createConfiguration.configuration.carrierReference}
                action={(
                  <IconButton onClick={() => copy(createConfiguration.configuration.carrierReference)}>
                    <CopyToClipboardIcon
                      className={styles.icon}
                      height="20"
                      data-tip="Copy to clipboard"
                    />
                  </IconButton>
                )}
                disabled
              />
            </div>
            <div className={`mt-4 ${styles.labelLeftCheck}`}>
              <InputWithValidation
                name="permission"
                label="I give permission for Track to retrieve tracking events on my behalf"
                type="checkbox"
                errors={createConfiguration.errors}
                onChange={this.handleChange}
              />
            </div>
            <div className={`${formStyles.depad} mt-4`}>
              <DualButtons
                leftFunction={this.handleCancelClick}
                leftText="Cancel"
                rightFunction={this.handleAddClick}
                rightText="Save"
                theme="rightPink"
              />
            </div>
            <div>
              {createConfiguration.error}
            </div>
          </div>
        )}
        {this.state.pageIndex === 1 && (
          <div>
            {createConfiguration.configuration.carrierEmail && (
              <div>
                <p>Please contact your carrier</p>
                <p>
                  Please contact&nbsp;
                  <a href={`mailTo:${createConfiguration.configuration.carrierEmail};`} className={formStyles.email}>
                    {createConfiguration.configuration.carrierEmail}
                  </a> and provide them with the following details:
                </p>
                <p>1. Your carrier account number if you have one.</p>
                <p>2. Your name and contact information.</p>
              </div>
            )}
            {!createConfiguration.configuration.carrierEmail && (
              <div>
                <p>You will need the details of your&nbsp;
                  {createConfiguration.configuration.carrierName} account
                  to set up this Carrier Connector.
                </p>
                <p>Please contact&nbsp;
                  {createConfiguration.configuration.carrierName} and
                  request your account details.
                </p>
                <p>When you have this information, click the&nbsp;
                  <b>I know my details</b> tab
                  and follow the setup instructions on-screen.
                </p>
              </div>
            )}
            <div className={`${styles.btnContainer} ${formStyles.depad}`}>
              <button
                type="button"
                name="create"
                onClick={this.handleCancelClick}
              >Cancel
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setCreateConfiguration: configuration => dispatch(setCreateConfigurationAction(configuration)),
  clearCreateConfiguration: () => dispatch(clearCreateConfigurationAction()),
  addConfiguration: configuration => dispatch(addConfigurationAction(configuration)),
});

export default withRouter(connect(state => ({
  createConfiguration: state.createConfiguration,
}), mapDispatchToProps)(CTrackingService));
