import { flattenData, replaceDates, toDate } from 'helpers/datetime';
import { hasProperty } from 'helpers/common';
import { parse as parseQuery, stringify as stringifyQuery } from './queryString';
import availableStates from './availableStates';

const fromAndToToDate = (dateFilter) => {
  if (dateFilter && hasProperty(dateFilter, 'from') && hasProperty(dateFilter, 'to')) {
    return {
      from: dateFilter.from ? toDate(dateFilter.from) : null,
      to: dateFilter.to ? toDate(dateFilter.to) : null,
    };
  }

  return dateFilter;
};

const parseFiltersSearchQuery = (queryString, availableCarriers, unixTimeToDate) => {
  const {
    registeredDate,
    orderDate,
    shippedDate,
    deliveryDate,
    promisedDate,
    estimatedDate,
    shippingDirection,
    carriers,
    origins,
    destinations,
    states,
    sort,
    page,
    pageSize,
    searchString,
    trackingReference,
    customReference,
    packageTrackingReference,
    late,
    mayBeMissing,
    calculatedEventsOperator,
    metadataKey,
    metadataValue,
    metaKey,
    metaValue,
    originPostalCode,
    destinationPostalCode,
    emailAddress,
    fileFormat,
  } = parseQuery(queryString);

  const result = {
    registeredDate: registeredDate && JSON.parse(registeredDate),
    orderDate: orderDate && JSON.parse(orderDate),
    shippedDate: shippedDate && JSON.parse(shippedDate),
    deliveryDate: deliveryDate && JSON.parse(deliveryDate),
    promisedDate: promisedDate && JSON.parse(promisedDate),
    estimatedDate: estimatedDate && JSON.parse(estimatedDate),
    trackingReference,
    customReference,
    packageTrackingReference,
    shippingDirection,
    sort,
    page,
    pageSize,
    searchString,
    late,
    mayBeMissing,
    calculatedEventsOperator,
    metadataKey,
    metadataValue,
    originPostalCode,
    destinationPostalCode,
    emailAddress,
    fileFormat,
  };

  if (unixTimeToDate) {
    result.registeredDate = fromAndToToDate(result.registeredDate);
    result.orderDate = fromAndToToDate(result.orderDate);
    result.shippedDate = fromAndToToDate(result.shippedDate);
    result.deliveryDate = fromAndToToDate(result.deliveryDate);
    result.promisedDate = fromAndToToDate(result.promisedDate);
    result.estimatedDate = fromAndToToDate(result.estimatedDate);
  }

  if (carriers && carriers.length > 0) {
    result.carriers = carriers;
    if (!Array.isArray(carriers)) result.carriers = [`${carriers}`];

    // we need to move back from ["carrier"] to [{ key: "carrier", name: "Awesome Carrier" }]
    if (availableCarriers && availableCarriers.length > 0) {
      result.carriers = result.carriers.map(carrier => availableCarriers.find(availableCarrier => (
        availableCarrier.key
          ? availableCarrier.key.toString() === carrier
          : availableCarrier.value.toString() === carrier
      )))
        .filter(carrier => carrier);
    } else {
      result.carriers = carriers;
      if (!Array.isArray(carriers)) result.carriers = [`${carriers}`];

      result.carriers = result.carriers.map(carrier => ({ key: carrier }));
    }
  }

  if (states && states.length > 0) {
    result.states = states;
    if (!Array.isArray(states)) result.states = [`${states}`];

    result.states = result.states.map(state => availableStates.find(
      availableState => availableState.value === state,
    ))
      .filter(state => state);
  }

  if (origins) {
    result.origins = [];
    for (let i = 0; i < origins.length; i += 2) {
      result.origins.push(origins.substr(i, 2));
    }
  }
  if (destinations) {
    result.destinations = [];
    for (let i = 0; i < destinations.length; i += 2) {
      result.destinations.push(destinations.substr(i, 2));
    }
  }
  if (metaKey && metaKey.length > 0) {
    result.metaKey = metaKey;
  }
  if (metaValue && metaValue.length > 0) {
    result.metaValue = metaValue;
  }

  return result;
};

const getBackendFilterQuery = (frontendFilterQuery) => {
  const parsedQuery = parseFiltersSearchQuery(frontendFilterQuery);
  const parsedDatesResult = replaceDates(parsedQuery);

  const flattenedDataResult = flattenData(parsedDatesResult);
  return stringifyQuery({
    ...flattenedDataResult,
    limit: parsedQuery.pageSize,
  });
};

export {
  parseFiltersSearchQuery,
  getBackendFilterQuery,
};
