import React from 'react';
import styles from './styles.scss';

const Switch = ({
  enabled,
  toggle,
  id,
  disabled,
  isLabelEnabled = true,
  enabledLabel = 'Active',
  disabledLabel = 'Inactive',
}) => (
  <div className={styles.enableSwitcher}>
    <div className={styles.switcherInner}>
      <input
        id={id}
        type="checkbox"
        className={styles.appleSwitch}
        disabled={disabled}
        onChange={() => toggle(id)}
        checked={enabled}
      />
      <label htmlFor={id} />
    </div>
    {isLabelEnabled && (
      <div className={styles.statusMode}>
        <p className={enabled ? styles.activeState : undefined}>
          {enabled ? enabledLabel : disabledLabel}
        </p>
      </div>
    )}
  </div>
);

export default Switch;
