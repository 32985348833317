import React from 'react';
import StateContainer from 'components/Timeline/StateContainer';
import StateItem from 'components/Timeline/StateItem';
import { isToday } from 'date-fns';
import styles from './style.scss';

const Timeline = ({ items, signee, errorMessage }) => {
  if (errorMessage) {
    return (
      <div className={styles.timelineGrid}>
        <div className={styles.timeline}>
          {errorMessage}
        </div>
      </div>
    );
  }

  if (!items || items.length === 0) {
    return (<div className={styles.timelineGrid} />);
  }

  const states = items
    .map(i => ({
      ...i,
      timestamp: i.tracking_events.reduce((acc, cur) => {
        const timeStamp = new Date(cur.timestamp);
        return timeStamp > acc ? timeStamp : acc;
      }, new Date(i.timestamp)),
    }))
    .reverse();

  function getStateItemTimestamp(trackingEvents, eventCode) {
    if (eventCode === 'Dispatched' && trackingEvents.length) {
      return trackingEvents.slice().reverse().find(el => el.event_code === 'ForInformation' || el.event_code === 'Dispatched').timestamp;
    }
    return trackingEvents.slice().reverse().find(el => el.event_code === eventCode).timestamp;
  }

  return (
    <ol className={styles.timelineGrid}>
      <StateContainer recipient>
        <span className={styles.recipient}>Recipient{signee ? ` - signed by ${signee}` : ''}</span>
      </StateContainer>
      {isToday(states[0].timestamp)
        && (
          <StateContainer>
            <span className={styles.stateDate}>Today</span>
          </StateContainer>
        )}
      {states.map((i, index) => (
        <StateContainer key={i.renderKey} category={i.state_category} isActive={index === 0}>
          <StateItem
            code={i.event_code}
            timestamp={getStateItemTimestamp(i.tracking_events, i.event_code)}
            events={i.tracking_events}
            isActive={index === 0}
            category={i.state_category}
          />
        </StateContainer>
      ))}
    </ol>
  );
};

export default Timeline;
